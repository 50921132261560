<template>
  <div class="chat h-100">
    <JobWantedHead />
    <el-row class="chat-card">
      <el-col :span="8" class="chat-card__left ta-c">
        <header class="left-header">
          <!-- <el-input
            v-model="keyword"
            size="small"
            suffix-icon="el-icon-search"
            placeholder="搜索"
          /> -->
        </header>
        <div class="contact" v-if="contactList.length">
          <el-row
            :class="['contact-item', { 'is-Checked': item.isChecked === true }]"
            v-for="item in contactList"
            :key="item.shop_user_job_message_id"
            @click.native="handleCurrentChat(item)"
          >
            <el-col class="avatar" :span="8">
              <img :src="item.pic" alt="HR头像" />
            </el-col>
            <el-col class="hr-info" :span="16">
              <el-row class="hrInfoTop">
                <el-col :span="6" class="hr-name">{{ item.principal }}</el-col>
                <el-col :span="14" class="company-info">{{
                  item.chain_name
                }}</el-col>
                <el-col :span="3" class="time">{{
                  item.messages.length
                    ? conversion(
                        item.messages[item.messages.length - 1].created
                      )
                    : null
                }}</el-col>
              </el-row>
              <div class="message">
                {{
                  item.messages.length
                    ? item.messages[item.messages.length - 1].personage
                    : null
                }}
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="15" class="chat-main">
        <header class="chat-main__header">
          <span class="hr-name">{{ currentContact.principal }}</span>
          <span class="ml-1 hr-info">{{ currentContact.chain_name }}</span>
        </header>
        <el-row class="job-info">
          <el-col :span="8">
            <h4 class="title">
              {{ currentContact.job_title }}
            </h4>
          </el-col>
          <el-col :span="12">
            <span class="salary ml-1"
              >{{ currentContact.job_salary }}·{{
                currentContact.job_salary_constitute
              }}</span
            >
            <span class="ml-1 city">{{ currentContact.job_city }}</span>
          </el-col>
          <el-col :span="4">
            <span class="jobInfo" @click="handleJobInfo">职位详情</span>
          </el-col>
        </el-row>
        <div class="chat-content">
          <section
            v-for="item in currentContact.messages"
            :class="[
              'chat-item',
              { 'is-left': item.company },
              { 'is-right': item.personage },
            ]"
            :key="item.shop_user_job_message_dialogue_id"
          >
            <div class="chat-item__main">
              <div class="chat-item__content">
                {{ item.personage || item.company }}
              </div>
            </div>
          </section>
        </div>
        <div class="send-message">
          <el-input
            type="textarea"
            cols="100"
            rows="5"
            placeholder="说点什么吧..."
            v-model="message"
          />
          <el-button type="primary" icon="el-icon-send" @click="send"
            >发送</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-dialog
      class="jobInfoDialog"
      :title="'职位描述'"
      :visible.sync="jobInfoVisible"
      width="40%"
    >
      <div class="tag-group">
        <el-tag v-for="item in currentContact.job_keyword" :key="item">
          {{ item }}
        </el-tag>
      </div>
      <span>{{ currentContact.job_describe }}</span>
    </el-dialog>
  </div>
</template>

<script>
import { Notification } from "element-ui";
import { getUserMessage, insertDialogue } from "@/request/api";
import JobWantedHead from "@/components/JobWantedHead.vue";
export default {
  name: "Chat",
  components: {
    JobWantedHead,
  },
  data() {
    return {
      keyword: "", // 搜索
      message: "",
      currentContact: {
        principal: "",
        chain_name: "",
        job_title: "",
        job_salary: "",
        job_salary_constitute: "",
        job_city: "",
        messages: [{}],
      },
      contactList: [
        {
          messages: [{}],
        },
      ],
      eventSource: null,
      jobInfoVisible: false,
    };
  },
  watch: {
    message(value, oldValue) {
      console.log(value, "value");
      console.log(oldValue, "oldValue");
    },
  },
  created() {
    // 获取历史聊天记录
    const contactList = window.localStorage.getItem("contactList");
    if (contactList) {
      this.contactList = JSON.parse(contactList);
      this.currentContact = JSON.parse(contactList)[0] || {};
    } else {
      this.getMessage();
    }
    this.getMessage();
    this.connectSSE();
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  },
  methods: {
    handleJobInfo() {
      this.jobInfoVisible = true;
    },
    connectSSE() {
      if (window.EventSource) {
        const usersinfomation = window.localStorage.getItem("usersinfomation");
        const shop_user_id = JSON.parse(usersinfomation).shop_user_id;

        this.eventSource = new EventSource(
          "https://www.bmbai.top/api/shopApi/chatGPT/SSE?shop_user_id=" +
            shop_user_id
        );
        this.eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (typeof data === "object" && data !== null) {
            let keys = Object.keys(data).length;
            if (keys !== this.contactList.length) {
              this.getMessage();
              return;
            }

            let i = 0;
            while (i < this.contactList.length) {
              const shop_user_job_message_id =
                this.contactList[i].shop_user_job_message_id;
              if (
                data[shop_user_job_message_id] &&
                data[shop_user_job_message_id] !==
                  this.contactList[i].messages.length
              ) {
                this.getMessage();
                break;
              }
              i++;
            }
          }
        };
      } else {
        console.log("Your browser doesn't support Server-Sent Events");
      }
    },
    getMessage() {
      getUserMessage()
        .then((res) => {
          if (this.$route.query.shop_user_job_id) {
            this.contactList = res.data.data.map((item) => {
              if (
                item.shop_user_job_id ===
                Number(this.$route.query.shop_user_job_id)
              ) {
                item.isChecked = true;
                this.currentContact = item;
              }
              item.job_keyword = item.job_keyword.split(",");
              return item;
            });
          } else {
            this.contactList = res.data.data;
            this.contactList[0].isChecked = true;
            this.currentContact = res.data.data[0];
          }
        })
        .catch((error) => {
          this.$message({
            title: "出错了",
            message: error.msg,
            type: "error",
          });
        });
    },
    conversion(time) {
      const date = new Date(time);
      const h = date.getHours();
      const m = date.getMinutes();
      console.log();
      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
    },
    send() {
      if (this.message.trim()) {
        this.currentContact.messages.push({
          role: "user",
          personage: this.message,
          id: Date.now(),
        });
        const body = {
          type: 2, //个人端
          shop_user_job_message_id:
            this.currentContact.shop_user_job_message_id, //消息id
          send: this.message,
        };
        insertDialogue(body)
          .then((res) => {
            if (res.data.code === "1000") {
              this.setContactList();
              this.message = "";
            }
          })
          .catch((error) => {
            this.$message({
              title: "发送失败",
              message: error.msg,
              type: "error",
            });
          });
      } else {
        Notification.warning({
          title: "警告",
          message: "消息内容不能为空",
        });
      }
    },
    // 除以1000,取整
    divSalary(count) {
      return Math.round(count / 1000);
    },
    // 保存当前对话列表 到缓存
    setContactList() {
      const index = this.contactList.findIndex(
        (item) =>
          item.shop_user_job_message_id ===
          this.currentContact.shop_user_job_message_id
      );
      if (index !== -1) {
        this.contactList.splice(index, 1, this.currentContact);
      } else {
        this.contactList.push(this.currentContact);
      }
      window.localStorage.setItem(
        "contactList",
        JSON.stringify(this.contactList)
      );
    },
    // 切换对话,切换对话前要先缓存当前对话
    handleCurrentChat(item) {
      this.setContactList();
      this.contactList = this.contactList.map((v) => {
        if (v.shop_user_job_message_id === item.shop_user_job_message_id) {
          v.isChecked = true;
        } else {
          v.isChecked = false;
        }
        return v;
      });
      this.currentContact = item;
    },
    // 返回首页前，需要把当前对话列表存起来
    goHome() {
      this.setContactList();
      // 返回首页
      this.$router.push("/").catch((e) => {
        console.error(e);
      });
    },
    sendExit() {
      // 处理退出逻辑
    },
  },
};
</script>
<style lang="less" scoped>
.chat {
  .jobInfoDialog {
    /deep/.el-dialog {
      line-height: 30px;
      min-height: 40%;
      background: linear-gradient(178deg, #ffffff 0%, #dffdff 100%);
    }
    .tag-group {
      margin-bottom: 30px;
      span {
        margin-right: 15px;
      }
    }
  }
  .chat-card {
    background: transparent;
    display: flex;
    height: 92%;
    padding: 0;
  }

  .chat-card__left {
    height: 100%;
    // width: 300px;
    background: #fff;
    border-radius: 16px;
    overflow-x: auto;
    .left-header {
      padding: 10px;
      height: 50px;
      background-image: linear-gradient(to right, #d5e7fc, #eaf2f4);
    }
  }

  .contact {
    padding: 10px;
    .is-Checked {
      background: #f2f2f2;
    }
    .contact-item {
      height: auto;
      cursor: pointer;
      line-height: 55px;
      padding: 8px;
      margin-bottom: 10px;
      border-radius: 6px;

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .hr-info {
        margin-left: 1em;
        text-align: left;
        font-size: 12px;
        line-height: 1.8;
        color: #666;
        .hrInfoTop {
          display: flex;
          align-items: flex-end;
        }
        .company-info {
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          white-space: nowrap; //文本不会换行
        }
        .hr-name {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          // margin-right: 0.5em;
          align-items: flex-end;
        }
        .message {
          width: 66%;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          white-space: nowrap; //文本不会换行
        }
      }

      &:hover {
        color: #027aff;
        background: #f3f3f3;
      }
    }
  }

  .chat-main {
    margin-left: 20px;
    // width: 720px;
    height: 100%;
    background: #fff;
    border-radius: 16px;

    .chat-main__header {
      padding: 1em 1em 0;

      .hr-name {
        font-size: 18px;
        font-weight: 600;
        margin-right: 1em;
      }

      .hr-info {
        color: #666;
        font-size: 12px;
      }
    }

    .job-info {
      margin: 1em;
      padding: 0 1em;
      background-image: linear-gradient(to right, #d7e9f7, #f2f2f2);
      line-height: 50px;
      border-radius: 8px;

      .title {
        font-size: 20px;
        font-weight: 600;
        margin-right: 3em;
      }

      .salary {
        font-size: 20px;
        font-weight: bold;
        color: #fdb228;
        margin-right: 2em;
      }
      .jobInfo {
        text-align: right;
        color: #4d94ff;
        cursor: pointer;
      }
      .city {
        font-size: 16px;
      }
    }

    .chat-content {
      height: 52%;
      padding-bottom: 20px;
      overflow-x: auto;
      .chat-item {
        --position: left;
        --avatar-bg: #dfe8fa;
        --content-bg: #f3f3f3;
        --content-color: #404040;
        margin: 1em 1em 0;
        overflow: hidden;

        .chat-item__main {
          float: var(--position);
          margin: 0 1em;
          max-width: 70%;
        }

        .chat-item__content {
          position: relative;
          width: 100%;
          line-height: 1.8;
          font-size: 15px;
          border-radius: 10px;
          color: var(--content-color);
          padding: 0.5em 1em;
          background: var(--content-bg);
          white-space: normal;
          word-break: break-all;
        }

        .chat-item__translate {
          margin-top: 0.5em;
          color: #666;
          width: 100%;
          line-height: 1.6;
          font-size: 14px;
          padding: 0.5em 1em;
          border-radius: 4px;
          white-space: normal;
          word-break: break-all;
          background: #fff;

          .translate-tip {
            color: #999;
            margin-top: 8px;

            .el-icon-success {
              margin-right: 10px;
            }
          }
        }

        &.is-left {
          .chat-item__content:before {
            position: absolute;
            top: 1em;
            left: -6px;
            border: 6px solid transparent;
            border-left: 0;
            border-right-color: var(--content-bg);
            content: "";
          }
        }

        &.is-right {
          --position: right;
          --avatar: url("https://photo.tuchong.com/14510776/f/596366497.jpg");
          --avatar-bg: #027aff;
          --content-bg: #027aff;
          --content-color: #fff;

          .chat-item__content:before {
            position: absolute;
            top: 1em;
            right: -6px;
            border: 6px solid transparent;
            border-right: 0;
            border-left-color: var(--content-bg);
            content: "";
          }
        }
      }
    }

    .send-message {
      position: relative;
      height: 117px;
      text-align: center;

      ::v-deep .el-textarea .el-textarea__inner {
        border-radius: 0 0 16px 16px;
        resize: none;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }

      .el-button {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
}
</style>
